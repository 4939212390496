import React from "react";

export default function Peripheristan() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/39.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Peripheristan. In Anti-Atlas. Ed. by W. Bracewell, T. Beasley, & M.
            Murawski.
            <br />
            UCL Press, 2025
          </h2>
          <p>
            The Anti-Atlas reflects forty-five different understandings of
            geopolitical critique and positionality. An eclectic book that plays
            with the politics of the conventional atlas, with its assumptions
            about knowledge and power.
          </p>
          <p>
            My entry sketches the outlines of a transnational continent of the
            (semi-) fringes. Less Moscow or London, more Leicester, Tampere,
            Murcia and Tallinn, as my own career evidences. The much-attested
            disadvantages of peripheral status, including the centres’ refusal
            to see such locales on their own terms, are here compensated by the
            advantages (ambiguity and distance) of relative remoteness – though
            peripherality can be as much a political condition as a state of
            mind and a geography of displacement.
          </p>
          <p>
            Available for free download from UCL Press:{" "}
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://uclpress.co.uk/book/anti-atlas/"
            >
              https://uclpress.co.uk/book/anti-atlas/
            </a>
          </p>
        </div>
      </div>
    </main>
  );
}
