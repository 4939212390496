import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <main className="home">
      <Helmet>
        <title>Francisco Martinez - Personal Website</title>
        <meta
          name="description"
          content="Anthropologist dealing with contemporary issues of material culture through ethnographic experiments."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
        <meta
          property="og:title"
          content="Francisco Martinez - Personal Website"
        />
        <meta
          property="og:description"
          content="Learn more about Francisco's work as an anthropologist and his ethnographic experiments."
        />
        <meta property="og:image" content="media/1_original.jpeg" />
        <meta property="og:url" content="https://fran-martinez.com/" />
      </Helmet>
      <div className="home-intro">
        <div className="container">
          <div className="row g-5">
            <div className="col-12 col-md-3 order-md-2 align-items-center justify-content-center text-center">
              <img
                src="media/1.jpg"
                alt="Francisco"
                className="img-fluid img-standard img-righty"
              />
            </div>
            <div className="col-12 col-md-9 order-md-1 d-flex flex-column justify-content-center">
              <p>
                I am an <span className="fw-bold">anthropologist</span> dealing
                with contemporary issues of{" "}
                <span className="fw-bold">
                  material culture through ethnographic research.
                </span>{" "}
                My work is known for its{" "}
                <span className="fw-bold">
                  critical insights and experimental style.
                </span>{" "}
                I was awarded with the Early Career Prize of the European
                Association of Social Anthropologists and currently work as a{" "}
                <span className="fw-bold">
                  Ramón y Cajal Senior Research Fellow
                </span>{" "}
                at the University of Murcia, Spain.
              </p>
              <p>
                Concerned with continuity and change, I have been studying the{" "}
                <span className="fw-bold">
                  materiality of political relations
                </span>{" "}
                in{" "}
                <span className="fw-bold">Estonia, Georgia and Portugal</span>.
                I have completed{" "}
                <span className="fw-bold">
                  four ethnographically based monographs
                </span>{" "}
                and several edited volumes and journal issues, which have been
                reviewed over a dozen times.
              </p>
              <p>
                I consider myself a fieldworker. Also, I make use of{" "}
                <span className="fw-bold">exhibitions for social research</span>{" "}
                and to{" "}
                <span className="fw-bold">
                  reach audiences outside academia
                </span>
                . I believe that these efforts to expand ethnographic ways of
                doing through{" "}
                <span className="fw-bold">experimental collaborations</span>{" "}
                animate transdisciplinary research and creative ways of
                disseminating knowledge.
              </p>

              <Link to="/Introduction" className="btn-home">
                More About Me &#8594;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
